<template>
  <div ref="box" class="media-box none-border record">
    <el-card v-if="info0">
      <el-page-header slot="header" @back="goBack" title="">
        <div slot="content" class="flex flex-v-center">
          <div class="flex flex-v-center">
            <div style="margin-left: -10px;">{{ source.type == 1 ? source.type1.title.replace("-", "") : source.tag }}
            </div>
            <el-divider direction="vertical"></el-divider>
            <h4 class="bold">{{ $t1(source,'title') }}</h4>
            <div class="flex">
              <el-tag effect="dark" class="margin-l">#{{ source.no }}</el-tag>
              <el-tag effect="dark" v-if="source.frequency == 1" class="margin-l">{{ $t('高频') }}</el-tag>
              <el-tag effect="dark" v-if="source.is_gmat" class="margin-l">{{ $t('机经') }}</el-tag>
              <el-tag effect="dark" v-if="source.type == 0 && source.level == 2" class="margin-l">{{ $t('难') }}</el-tag>
              <el-tag effect="dark" v-else-if="source.type == 0 && source.level == 1" class="margin-l">{{ $t('中')
                }}</el-tag>
              <el-tag effect="dark" v-else-if="source.type == 0 && source.level == 0" class="margin-l">{{ $t('简')
                }}</el-tag>
                <el-tag effect="dark" v-if="source.is_forecast == 1" class="margin-l">{{ $t('预测') }}</el-tag>
              <el-tag effect="dark" v-if="source.is_back" class="margin-l">{{ $t('老题重回') }}</el-tag>
              <el-tag effect="dark" v-if="source.is_updated" class="margin-l">{{ $t('更新') }}</el-tag>
              <el-tag class="margin-l" v-if="['SSL', 'RL'].indexOf(source.tag) != -1" effect="dark">{{
                source.audio == 2
                  ? $t('近似音频') : source.audio == 1 ? $t('原音频') : $t('无音频')
              }}</el-tag>
            </div>
          </div>
        </div>
      </el-page-header>

      <div>
        <div v-if="['RS', 'RL', 'WFD', 'HIW', 'FIB-L', 'SMW', 'HCS', 'MCS', 'MCM-L', 'SST'].indexOf(source.tag) != -1"
          style="margin-bottom: 20px;">
          <!-- <audio-player ref="player" @play="playStart" @ended="playerEnd" :no="source.no" :sort="source.sort" /> -->
          <h4>{{ $t('原文音频') }}:</h4> <wave-player class="margin-ts"
            :src="$host + '/api/audio/' + source.no + '/Origin'"></wave-player>
        </div>


        <div v-if="['DI'].indexOf(source.tag) != -1">
          <div class="bold">{{ $t('原图') }}:</div>
          <div class="flex flex-h-center margin-t">
            <el-image fit="contain" style="width: 400px;" ref="image" @click="preview" :preview-src-list="[imageUrl()]"
              :src="imageUrl()"></el-image>
          </div>
        </div>



        <div v-else-if="['HIW', 'FIB-L', 'FIB-R', 'FIB-RW'].indexOf(source.tag) != -1">
          <h4>{{ $t(getLabel()) }}:</h4>
          <div>
            <div class="flex flex-wrap">
              <span v-for="(item, index) in words" @click="$bus.$emit('word', item.word)" class="word" :key="index"
                :class="item.class">{{ item.word }}</span>
            </div>
          </div>
        </div>

        <div v-else>
          <div class="ls1" v-if="source.words_en">
            <h4 v-if="source.type == 0">{{ $t(getLabel()) }}:</h4>
            <div v-for="(row, index) in source.words_en.split('\n')" :key="'row' + index">
              <div v-if="source.type == 1">
                <div class="st"> Segment {{ index + 1 }}</div>

                <div class="flex flex-v-center st1 margin-ts">{{ $t('原文音频') }}：<wave-player
                    :src="$host + '/api/audio/ccl_' + source.no + '_' + index + '/Origin'"></wave-player>
                </div>
              </div>
              <div class="flex flex-wrap">
                <span class="word pointer" @click="$bus.$emit('word', word)"
                  v-for="(word, index1) in row.replaceAll('^', '').split(' ')" :key="'word' + index1">{{
                    word
                  }}
                </span>
              </div>

              <div v-if="source.type == 1">

                <div class="flex flex-wrap">
                  <span class="word" @click="$bus.$emit('word', word)"
                    v-for="(word, index1) in source.words_zh.split('\n')[index].replaceAll('^', '').split(' ')"
                    :key="'word' + index1">{{
                      word
                    }}
                  </span>
                </div>


                <div v-if="info0.content[index]" class="margin-lt">
                  <div class="st1 flex flex-v-center">{{ $t('答案音频') }}：<wave-player controls
                      :src="$host + info0.content[index].url"></wave-player></div>
                </div>

                <div v-if="info0.audit_content[index]" class="margin-ts">
                  <div class="st">{{ $t('老师回复') }}：</div>
                  <div>{{ info0.audit_content[index].text }}</div>
                  <div class="margin-t" v-if="info0.audit_content[index].url"><wave-player controls
                      :src="$host + info0.audit_content[index].url"></wave-player></div>
                </div>
                <el-divider></el-divider>
              </div>
            </div>
          </div>
        </div>

        <div class="ls1 margin-ts" v-if="source.tag == 'RS'">
          <div>{{ source.words_zh }}</div>
        </div>
      </div>

      <div v-if="info0.type == 0">
        <div v-if="info0.content && info0.content.indexOf('.wav') != -1">
          <div class="margin-lt">
            <el-table :border="true" :data="tableData" style="width: 100%">
              <el-table-column prop="item1" :label="$t('单项')" width="160px">
                <template slot-scope="scope">
                  <div>{{ $t(scope.row.item1) }}
                    <i slot="reference" @click="showDialog(scope.row.item1)"
                      class="el-icon-question lt color-green pointer"></i>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="item2" :label="$t('得分')">
                <template slot-scope="scope">
                  <div class="st bold">{{ scope.row.item2 }}</div>
                  <el-progress v-if="source.tag != 'ASQ'" status="success" :percentage="scope.row.item4"
                    :show-text="false"></el-progress>
                </template>

              </el-table-column>

              <!-- <el-table-column prop="item3" label="">
                                                                                                                                                                        <template slot-scope="scope">
                                                                                                                                                                          <el-progress :status="scope.row.item4 < 60 ? 'exception' : 'success'" :percentage="scope.row.item4"
                                                                                                                                                                            :show-text="false"></el-progress>
                                                                                                                                                                        </template>
                                                                                                                                                                      </el-table-column> -->
            </el-table>


          </div>


          <div class="margin-t flex flex-v-center">
            <div class="mt" v-if="source.tag == 'ASQ'">{{ $t('评分') }}：<span class="color-primary">{{ info0.score
                }}</span>
              / 1</div>
            <div class="mt" v-else>{{ $t('评分') }}：<span class="color-primary">{{ parseInt(info0.score * 0.9) }}</span> /
              90</div>
            <wave-player class="margin-x" :src="$host + info0.content"></wave-player>
            <div class="st">{{ info0.result_time }}</div>
          </div>
          <div v-if="info">
            <el-divider>
              <div class="flex flex-v-center">
                <h3>{{ $t('AI识别') }}</h3>
              </div>
            </el-divider>
            <div v-if="source.tag == 'ASQ'">
              {{ info0.result }}
            </div>
            <div v-else>
              <div v-if="['RA', 'RS'].indexOf(source.tag) == -1" class="flex flex-h-end">
                <div class="flex flex-v-center margin-x" v-for="(item, index) in items.slice(1)" :key="index">
                  <div class="dot" :class="'v' + (index + 1)"></div>
                  <div>{{ $t(item) }}</div>
                </div>

                <div class="flex flex-v-center margin-x">
                  <div>/ {{ $t('停顿') }}</div>
                </div>
              </div>

              <div v-else class="flex flex-h-end">
                <div class="flex flex-v-center margin-x" v-for="(item, index) in items" :key="index">
                  <div class="dot" :class="'v' + index"></div>
                  <div>{{ $t(item) }}</div>
                </div>

                <div class="flex flex-v-center margin-x">
                  <div>/ {{ $t('停顿') }}</div>
                </div>
              </div>

              <div class="flex flex-wrap" v-if="info && info.words">

                <el-popover width="400" @show="showDetail(item.word)" placement="top" trigger="click"
                  v-for="(item, index) in info.words" :key="index">
                  <div class="st1">{{ item.pronunciation > 80 ? $t("发音正确，请继续保持") : item.pronunciation > 60 ?
                    $t("发音有点问题，稍作改变会更完美")
                    : item.pronunciation > 0 ? $t("发音糟糕，请听正确发音并纠正") : $t("没有读这个单词") }}</div>
                  <div class="line margin-ts"></div>
                  <h3 class="margin-ts">{{ item.word }}</h3>
                  <div class="st"><span style="margin-right: 10px;">{{ $t('你的发音') }}:</span>[<span
                      v-for="(ph, index1) in item.phonemes"
                      :class="{ 'v0': ph.pronunciation < 60, 'v1': ph.pronunciation >= 60 && ph.pronunciation <= 80, 'v2': ph.pronunciation >= 80 }"
                      :key="index1">{{ ph.phoneme }}</span>]
                  </div>
                  <div v-loading="loading1">
                    <div class="st1 flex flex-v-center">[UK]<span style="margin:0 10px;">[{{ wordInfo.phonetic[0]
                        }}]</span>
                      <el-image @click="play(1, item.word)" class="margin-x"
                        style="width: 24px; height: 24px;cursor: pointer;" src="/web/image/playing.png"></el-image>
                    </div>
                    <div class="st1 flex flex-v-center">[US]<span style="margin:0 10px;">[{{
                      wordInfo.phonetic[1] }}]</span>
                      <el-image @click="play(2, item.word)" class="margin-x"
                        style="width: 24px; height: 24px;cursor: pointer;" src="/web/image/playing.png"></el-image>
                    </div>
                    <div class="st1">
                      <div v-for="(row, index) in wordInfo.explanation.split('\n')" :key="index">
                        {{ row }}
                      </div>
                    </div>
                  </div>
                  <span slot="reference" class="word" :class="getClass(item)">{{ item.word }}
                    <span style="color:#333"
                      v-if="index < info.words.length - 1 && (info.words[index + 1].start - info.words[index].end > 0.4)">//</span>
                    <span style="color:#333"
                      v-else-if="index < info.words.length - 1 && (info.words[index + 1].start - info.words[index].end > 0.1)">/</span>

                  </span>
                </el-popover>


              </div>
              <div class="margin-t center st1">{{ $t('点击彩色单词可查看解析') }}</div>
            </div>

          </div>

          <div class="st margin-t" v-else>{{ $t('未评测') }}!</div>
        </div>

        <div v-else>
          <div v-if="['SMW', 'MCS', 'HCS', 'MCM-L', 'MCS-L', 'MCS-R', 'MCM-R'].indexOf(this.source.tag) != -1"
            class="margin-lt mt">
            <span class="word" v-for="(word, index) in source.question.split(' ')" :key="index">{{ word }}</span>
          </div>

          <div v-if="source.tag == 'HCS' || source.tag == 'MCS-L' || source.tag == 'SMW' || source.tag == 'MCS-R'">
            <div v-for="(words, index) in options" :key="index">
              <el-radio disabled class="margin-t" :label="choice[index]" v-model="content[0]">
                <span
                  :class="{ 'right': submitted == 1 && (content.indexOf(choice[index]) != -1 || answers.indexOf(choice[index]) != -1), 'wrong': submitted == 1 && content.indexOf(choice[index]) != -1 && answers.indexOf(choice[index]) == -1, 'choose': submitted == 1 && content.indexOf(choice[index]) != -1 }"
                  v-for="(word, index1) in words" :key="index1" class="word">{{ word }}</span>
                <!-- <i v-if="(submitted == 1 && content.indexOf(choice[index]) != -1 && answers.indexOf(choice[index]) != -1)"
                  style="color:green;font-weight:bold;" class="check-icon el-icon-check"></i> -->

                <!-- <i v-if="submitted == 1 && content.indexOf(choice[index]) != -1 && answers.indexOf(choice[index]) == -1"
                              style="color:red;font-weight:bold;" class="check-icon el-icon-close"></i> -->
              </el-radio>
            </div>
          </div>

          <el-checkbox-group :disabled="submitted == 1" v-if="source.tag == 'MCM-L' || source.tag == 'MCM-R'"
            v-model="content">
            <div v-for="(words, index) in options" :key="index">
              <el-checkbox class="margin-t" :label="choice[index]">
                <span v-for="(word, index1) in words" :key="index1" class="word"
                  :class="{ 'right': submitted == 1 && (content.indexOf(choice[index]) != -1 || answers.indexOf(choice[index]) != -1), 'wrong1': submitted == 1 && content.indexOf(choice[index]) != -1 && answers.indexOf(choice[index]) == -1, 'choose': submitted == 1 && content.indexOf(choice[index]) != -1 }">{{
                    word
                  }}</span>

                <!-- <i v-if="(submitted == 1 && content.indexOf(choice[index]) != -1 && answers.indexOf(choice[index]) != -1)"
                  style="color:green;font-weight:bold;" class="el-icon-check"></i> -->
                <!-- 
                <i v-if="submitted == 1 && content.indexOf(choice[index]) != -1 && answers.indexOf(choice[index]) == -1"
                  style="color:green;font-weight:bold;" class="el-icon-close"></i> -->
              </el-checkbox>
            </div>
          </el-checkbox-group>

          <div class="mt" v-if="['FIB-RW', 'WFD', 'HIW', 'FIB-L', 'MCS-R', 'MCM-R', 'FIB-R'].indexOf(source.tag) == -1">
            <el-divider></el-divider>
            <h4>{{ $t(getAnswerLabel()) }}：</h4>

            <div v-if="['SWT', 'WE', 'SST'].indexOf(source.tag) != -1">
              <div class="flex flex-wrap" v-for="(row, index) in source.answer.replace(/\^/g, '').split('\n')"
                :key="'row' + index">
                <span class="word pointer" v-word v-for="(word, index1) in row.split(' ')" :key="'word' + index1">{{
                  word
                  }}
                </span>
              </div>
            </div>
            <div v-else class="word">{{ source.answer }}</div>
          </div>
          <el-divider></el-divider>
          <div class="mt" v-if="source.tag && ['HIW', 'SMW', 'HCS', 'MCS', 'MCM-L', 'MCM-R'].indexOf(source.tag) == -1">
            <h4> {{ $t('我的答案') }}：</h4>

            <div class="flex flex-wrap" v-if="['WFD', 'FIB-RW', 'FIB-R'].indexOf(source.tag) != -1">
              <span v-word v-for="(item, index) in answers" class="mt word" :key="index" :class="item.class">{{
                item.word
                }}</span>
            </div>

            <div class="flex flex-wrap" v-else-if="['SST', 'SWT'].indexOf(source.tag) != -1">
              <span v-for="(word, index) in info0.content.split(' ')" v-word class="mt word pointer" :key="index">{{
                word
              }}</span>
            </div>
            <div v-else-if="['WE'].indexOf(source.tag) != -1">
              <div
                v-for="(row, index) in info0.content.split('\n')"
                :key="index">
                <div v-if="row" class="flex flex-wrap">
                    <span v-for="(word, index1) in row.split(' ')" 
                      v-word class="mt word pointer" :key="index1">{{
                        word
                      }}</span>
                </div>
                <div v-else style="height: 10px;"></div>
              </div>

            </div>
            <div v-else>
              <div class="word">
                {{ info0.content.replace(/\/[^,]+/g, '') }}
              </div>
              <!-- <div v-else> {{ info0.content }}</div> -->
            </div>
          </div>


          <div class="st flex flex-between flex-v-center margin-t">
            <div><span>{{ $t('得分') }}：{{ info0.score }}</span>
              <span
                v-if="['FIB-RW', 'HIW', 'FIB-L', 'SMW', 'HCS', 'MCS', 'MCM-L', 'MCS-R', 'MCM-R', 'FIB-R'].indexOf(source.tag) != -1">/{{
                  source.answer.split(',').length
                }}</span>
              <span v-else-if="['WFD'].indexOf(source.tag) != -1">/{{ source.answer.split(' ').length }}</span>
              <span v-else-if="['SWT'].indexOf(source.tag) != -1">/7</span>
              <span v-else-if="['WE'].indexOf(source.tag) != -1">/15</span>
              <span v-else-if="['SST'].indexOf(source.tag) != -1">/10</span>
              <span v-else-if="['RO'].indexOf(source.tag) != -1">/{{ source.answer.split(',').length - 1 }}</span>
              <span style="margin-left:10px;" v-if="['WE', 'SWT', 'SST'].indexOf(source.tag) != -1">{{ $t('字数') }}：{{
                info0.content.split(/\s+/).length
              }}</span>

            </div>
            <div style="color:#999">{{ info0.record_time }}</div>
          </div>

          <div class="st">{{ $t('详细解析请见海澳英语官网') }}<a href="https://www.ptefighter.com"
              class="margin-x ul">www.ptefighter.com</a>
          </div>

          <el-table class="margin-lt" v-if="source.tag == 'SWT' || source.tag == 'WE' || source.tag == 'SST'"
            :border="true" :data="tableData" style="width: 100%">
            <el-table-column prop="item1" :label="$t('评分项')">
              <template slot-scope="scope">
                <div>{{ scope.row.item1 }}
                  <i slot="reference" @click="showDialog(scope.row.item1)"
                    class="el-icon-question lt color-green pointer"></i>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="item2" :label="$t('得分')">
              <template slot-scope="scope">
                <div>{{ scope.row.item2 }}</div>
                <el-progress v-if="source.tag != 'ASQ'" :status="scope.row.item4 < 60 ? 'exception' : 'success'"
                  :percentage="scope.row.item4" :show-text="false"></el-progress>
              </template>
            </el-table-column>

          </el-table>

          <div v-if="info0.source.tag == 'SWT' || info0.source.tag == 'WE' || info0.source.tag == 'SST'"
            class="margin-t">

            <div class="st bold margin-bs">
              {{ $t('AI评测结果') }}:
            </div>
            <div v-for="(row, i) in info.words.split('\n')" :key="i">
              <div v-if="!row" style="height: 10px;"></div>
              <div v-else class="flex flex-wrap">
                <template v-for="(word, index) in row.replace(/\./g, '. ').replace(/\s+/g, ' ').split(' ')">
                  <el-popover :key="index" v-if="word.indexOf('^') != -1" placement="top-start" title="" trigger="hover"
                    :content="word.split('^')[1]">
                    <span class="pointer word" style="color:red;text-decoration: underline;" slot="reference">{{
                      word.split('^')[0] }}</span>
                  </el-popover>
                  <template v-else>
                    <span v-word :key="index" :style="{color: (word == row.replace(/\./g, '. ').replace(/\s+/g, ' ').split(' ')[index-1] ||word == row.replace(/\./g, '. ').replace(/\s+/g, ' ').split(' ')[index+1])?'red':''}" :class="word.replace(/\W/g, '') ? 'word' : 'word-dot'" class="pointer"
                      v-if="word">{{ word }}</span>
                  </template>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="info0.type == 1" class="st">{{ $t('详细解析请见海澳英语官网') }}<a href="https://www.ptefighter.com"
          class="margin-x ul">www.ptefighter.com</a></div>
    </el-card>

    <div v-if="ids" style="position:fixed;bottom: 0;left:0;right:0;background-color: #ccc;z-index: 1000;"
      class="padding flex flex-h-center">
      <el-button @click="next(-1)" :plain="ids.indexOf(id) == 0" :disabled="ids.indexOf(id) == 0" round class="margin-r"
        type="primary">{{$t('上一题')}}<i class="el-icon-top"></i></el-button>
      <el-button :plain="ids.indexOf(id) == ids.length - 1" @click="next(1)"
        :disabled="ids.indexOf(id) == ids.length - 1" round classs="margin-l" type="primary">{{$t('下一题')}}<i
          class="el-icon-bottom"></i></el-button>
    </div>

    <div v-if="ids" style="height: 100px;"></div>

    <el-dialog :title="dialogType" :visible.sync="centerDialogVisible" width="600" center>


      <div class="st1 break" v-if="dialogType == 'Content'">
        <div class="bold">{{ $t('评分规则') }}:</div>

        <div v-if="source.tag == 'SWT'">
          <div><strong>2:</strong> Provides a good summary of the text. All relevant aspects mentioned</div>
          <div><strong>1:</strong> Provides a fair summary of the text but misses one or two aspects</div>
          <div><strong>0:</strong> Omits or misrepresents the main aspects of the text</div>
          <div class="bold margin-t">{{ $t('答题建议') }}:</div>
          <div>{{ $t('一般来说，分段式文章，一个段落会有1-2个关键句，每段首句和尾句比较重要。本项如果是0分，电脑停止评判，本题直接0分') }}。</div>
        </div>

        <div v-else-if="source.tag == 'WE'">
          <div><strong>3:</strong> Adequately deals with the prompt</div>
          <div><strong>2:</strong> Deals with the prompt but does not deal with one minor aspect</div>
          <div><strong>1:</strong> Deals with the prompt but omits a major aspect or more than one minor aspect</div>
          <div><strong>0:</strong> Does not deal properly with the prompt</div>

          <div class="bold margin-t">{{ $t('答题建议') }}:</div>
          <div>{{ $t('PTE写作对于内容要求不高，不用做到逻辑严谨，只要观点和支持合理即可') }}。</div>
        </div>

        <div v-else-if="source.tag == 'SST'">
          <div><strong>2:</strong> Provides a good summary of the text. All relevant aspects are mentioned</div>
          <div><strong>1:</strong> Provides a fair summary of the text, but one or two aspects are missing</div>
          <div><strong>0:</strong> Omits or misrepresents the main aspects</div>
          <div class="bold margin-t">{{ $t('答题建议') }}:</div>
          <div>{{ $t('SST基本重要信息有4-5点，pte是机考，所以看不懂行文逻辑，同学可以把重点放到中心词上') }}。</div>
        </div>
      </div>


      <div class="st1 break" v-if="dialogType == 'Form'">
        <div class="bold">{{ $t('评分规则') }}:</div>
        <div v-if="source.tag == 'SWT'">
          <div><strong>1:</strong> Is written in one, single, complete sentence</div>
          <div><strong>0:</strong> Not written in one, single, complete sentence or contains fewer than 5 or more than
            75
            words. Summary is
            written in capital letters</div>
          <div class="bold margin-t">{{ $t('答题建议') }}:</div>
          <div>{{ $t('字数5-75之间，且只有一个句号，即可得分；如果写成两句或者以上，电脑停止评判，本题直接0分') }}。</div>
        </div>

        <div v-else-if="source.tag == 'WE'">
          <div><strong>2:</strong> Length is between 200 and 300 words</div>
          <div><strong>1:</strong> Length is between 120 and 199 or between 301 and 380 words</div>
          <div><strong>0:</strong> Length is less than 120 or more than 380 words. Essay is written in capital letters,
            contains no
            punctuation or only consists of bullet points or very short sentences</div>

          <div class="bold margin-t">{{ $t('答题建议') }}:</div>
          <div>{{ $t('保底分，只要字数写到200-300单词之间即可拿到满分2分') }}。</div>
        </div>


        <div v-else-if="source.tag == 'SST'">
          <div><strong>2:</strong> Contains 50-70 words</div>
          <div><strong>1:</strong> Contains 40-49 words or 71-100 words</div>
          <div><strong>0:</strong> Contains less than 40 words or more than 100 words. Summary is written in capital
            letters, contains no
            punctuation or consists only of bullet points or very short sentences</div>

          <div class="bold margin-t">{{ $t('答题建议') }}:</div>
          <div>{{ $t('答案在50-70个单词即可，没有其他要求') }}。</div>
        </div>
      </div>


      <div class="st1 break" v-if="dialogType == 'Grammar'">
        <div class="bold">{{ $t('评分规则') }}:</div>
        <div v-if="source.tag == 'SWT'">
          <div><strong>2:</strong> Has correct grammatical structure</div>
          <div><strong>1:</strong> Contains grammatical errors but with no hindrance to communication</div>
          <div><strong>0:</strong> Has defective grammatical structure which could hinder communication</div>

          <div class="bold margin-t">{{ $t('答题建议') }}:</div>
          <div>{{ $t('占分相对较高，建议使用简单句，不犯语法错误即可满分') }}。</div>
        </div>

        <div v-else-if="source.tag == 'WE'">
          <div><strong>2:</strong> Shows consistent grammatical control of complex language. Errors are rare and
            difficult
            to spot</div>
          <div><strong>1:</strong> Shows a relatively high degree of grammatical control. No mistakes which would lead
            to
            misunderstandings
          </div>
          <div><strong>0:</strong> Contains mainly simple structures and/or several basic mistakes</div>

          <div class="bold margin-t">{{ $t('答题建议') }}:</div>
          <div>{{ $t('语法需要需要复杂句，不犯语法错误') }}。</div>
        </div>

        <div v-else-if="source.tag == 'SST'">
          <div><strong>2:</strong> Has correct grammatical structure</div>
          <div><strong>1:</strong> Contains grammatical errors but with no hindrance to communication</div>
          <div><strong>0:</strong> Has defective grammatical structure which could hinder communication</div>

          <div class="bold margin-t">{{ $t('答题建议') }}:</div>
          <div>{{ $t('语法建议大家写简单句，不要尝试复杂句，因为要求是语法正确即可，而非长难句或者复杂句的表达形式') }}。</div>
        </div>
      </div>

      <div class="st1 break" v-if="dialogType == 'Spelling'">
        <div class="bold">{{ $t('评分规则') }}:</div>
        <div v-if="source.tag == 'WE'">
          <div><strong>2:</strong> Correct spelling</div>
          <div><strong>1:</strong> One spelling error</div>
          <div><strong>0:</strong> More than one spelling error</div>
          <div class="bold margin-t">{{ $t('答题建议') }}:</div>
          <div>{{ $t('拼写一个错误扣1分，注意不要英美混写') }}。</div>
        </div>
        <div v-else-if="source.tag == 'SST'">
          <div><strong>2:</strong> Has correct grammatical structure</div>
          <div><strong>1:</strong> Contains grammatical errors but with no hindrance to communication</div>
          <div><strong>0:</strong> Has defective grammatical structure which could hinder communication</div>

          <div class="bold margin-t">{{ $t('答题建议') }}:</div>
          <div>{{ $t('注意单词拼写，不能英美混写') }}。</div>
        </div>
      </div>


      <div class="st1 break" v-if="dialogType == 'Vocabulary range'">
        <div v-if="source.tag == 'WE'">
          <div><strong>2:</strong> Good command of a broad lexical repertoire, idiomatic expressions and colloquialisms
          </div>
          <div><strong>1:</strong> Shows a good range of vocabulary for matters connected to general academic topics.
            Lexical shortcomings
            lead to circumlocution or some imprecision</div>
          <div><strong>0:</strong> Contains mainly basic vocabulary insufficient to deal with the topic at the required
            level</div>
          <div class="bold margin-t">{{ $t('答题建议') }}:</div>
          <div>{{ $t('词汇使用海澳专属10个观点句套所有题目，轻松搞定') }}。</div>
        </div>
      </div>


      <div class="st1 break" v-if="dialogType == 'General linguistic range'">
        <div v-if="source.tag == 'WE'">
          <div><strong>2:</strong> Exhibits smooth mastery of a wide range of language to formulate thoughts precisely,
            give emphasis,
            differentiate and eliminate ambiguity. No sign that the test taker is restricted in what they want to
            communicate</div>
          <div><strong>1:</strong> Sufficient range of language to provide clear descriptions, express viewpoints and
            develop arguments
          </div>
          <div><strong>0:</strong> Contains mainly basic language and lacks precision</div>
          <div class="bold margin-t">{{ $t('答题建议') }}:</div>
          <div>{{ $t('套用海澳专属模版结构，轻松搞定') }}。</div>
        </div>
      </div>


      <div class="st1 break" v-if="dialogType == 'Development, structure and coherence'">
        <div v-if="source.tag == 'WE'">
          <div><strong>2:</strong> Shows good development and logical structure</div>
          <div><strong>1:</strong> Is incidentally less well structured, and some elements or paragraphs are poorly
            linked
          </div>
          <div><strong>0:</strong> Lacks coherence and mainly consists of lists or loose elements</div>
          <div class="bold margin-t">{{ $t('答题建议') }}:</div>
          <div>{{ $t('要呈现4段式/5段式，主题段要有观点句 (中心句)和支持句') }}。</div>
        </div>
      </div>


      <div class="st1 break" v-if="dialogType == 'Vocabulary'">
        <div class="bold">{{ $t('评分规则') }}:</div>
        <div><strong>2:</strong> Has appropriate choice of words</div>
        <div><strong>1:</strong> Contains lexical errors but with no hindrance to communication</div>
        <div><strong>0:</strong> Has defective word choice which could hinder communication</div>

        <div class="bold margin-t">{{ $t('答题建议') }}:</div>
        <div>{{ $t('要求是恰当的单词，允许使用原文词汇，不用替换单词') }}。</div>
      </div>





      <div class="st1 break" v-if="dialogType == '流利度'">
        <div class="bold">评分规则:</div>
        <div>Each replacement, omission or insertion of a word counts as one error</div>

        <div class="bold margin-t">{{ $t('答题建议') }}:</div>
        <div>{{ $t('流利度不仅仅是不卡顿，不重复和不犹豫，还包含了意群断句，节奏感，语速适中等其他要求') }}。</div>
      </div>

      <div class="st1 break" v-if="dialogType == '发音'">
        <div class="bold">{{ $t('评分规则') }}:</div>
        <div>
          <strong>5:</strong> All vowels and consonants are produced in a manner that is easily understood by regular
          speakers
          of the language. The speaker uses assimilation and deletions appropriate to continuous speech.
          Stress is placed correctly in all words and sentence-level stress is fully appropriate
        </div>
        <div>
          <strong>4:</strong> Vowels and consonants are pronounced clearly and unambiguously. A few minor consonant,
          vowel
          or
          stress distortions do not affect intelligibility. All words are easily understandable. A few
          consonants or consonant sequences may be distorted. Stress is placed correctly on all common
          words,
          and sentence level stress is reasonable
        </div>
        <div>
          <strong>3:</strong> Most vowels and consonants are pronounced correctly. Some consistent errors might make a
          few
          words
          unclear. A few consonants in certain contexts may be regularly distorted, omitted or
          mispronounced.
          Stress-dependent vowel reduction may occur on a few words
        </div>
        <div>
          <strong>2:</strong> Some consonants and vowels are consistently mispronounced in a non-native like manner. At
          least
          2/3 of speech is intelligible, but listeners might need to adjust to the accent. Some consonants
          are
          regularly omitted, and consonant sequences may be simplified. Stress may be placed incorrectly on
          some words or be unclear
        </div>
        <div>
          <strong> 1:</strong> Many consonants and vowels are mispronounced, resulting in a strong intrusive foreign
          accent.
          Listeners may have difficulty understanding about 1/3 of the words. Many consonants may be
          distorted
          or omitted. Consonant sequences may be non-English. Stress is placed in a non-English manner;
          unstressed words may be reduced or omitted and a few syllables added or missed
        </div>

        <div>
          <strong>0:</strong> Pronunciation seems completely characteristic of another language. Many consonants and
          vowels
          are
          mispronounced, misordered or omitted. Listeners may find more than 1/2 of the speech
          unintelligible.
          Stressed and unstressed syllables are realized in a non-English manner. Several words may have the
          wrong number of syllables
        </div>

        <div class="bold margin-t">{{ $t('答题建议') }}:</div>
        <div>{{ $t('发音的评分标准比较综合，一共包含以下几点: 第一，音准问题，做到元音饱满辅音清晰；第二，有重弱读和单词重音；第三，要注意连读和省读现象；第四，不能蹦单词同时不能拖音') }}。</div>
      </div>

      <div class="st1 break" v-if="dialogType == '内容分'">
        <div class="bold">{{ $t('评分规则') }}:</div>
        <div>
          <strong>5:</strong> Speech shows smooth rhythm and phrasing. There are no hesitations, repetitions, false
          starts
          or
          non-native phonological simplifications
        </div>
        <div>
          <strong>4:</strong> Speech has an acceptable rhythm with appropriate phrasing and word emphasis. There is no
          more
          than
          one hesitation, one repetition or a false start. There are no significant non- native phonological
          simplifications
        </div>
        <div>
          <strong>3:</strong> Speech is at an acceptable speed but may be uneven. There may be more than one hesitation,
          but
          most words are spoken in continuous phrases. There are few repetitions or false starts. There are
          no
          long pauses and speech does not sound staccato
        </div>
        <div>
          <strong>2:</strong> Speech may be uneven or staccato. Speech (if >= 6 words) has at least one smooth
          three-word
          run,
          and no more than two or three hesitations, repetitions or false starts. There may be one long
          pause,
          but not two or more
        </div>
        <div>
          <strong>1:</strong> Speech has irregular phrasing or sentence rhythm. Poor phrasing, staccato or syllabic
          timing,
          and/or multiple hesitations, repetitions, and/or false starts make spoken performance notably
          uneven
          or discontinuous. Long utterances may have one or two long pauses and inappropriate sentence-level
          word emphasis
        </div>
        <div>
          <strong>0:</strong> Speech is slow and labored with little discernable phrase grouping, multiple hesitations,
          pauses,
          false starts, and/or major phonological simplifications. Most words are isolated, and there may be
          more than one long pause
        </div>
        <div class="bold margin-t">{{ $t('答题建议') }}:</div>
        <div>{{ $t('替换，漏读或者添加单词都是一个错误。考试遇到大词难词，可以直接跳过。不建议使用something替代难词，因为这样扣分比直接跳过要高') }}。</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" @click="centerDialogVisible = false">{{ $t('关闭') }}</el-button>
      </span>
    </el-dialog>
    <word-dialog ref="wordDialog" :type="1"></word-dialog>
    <audio ref="audio"></audio>
  </div>
</template>

<script>
// import audioPlayer from '../components/AudioPlayer.vue'
import WavePlayer from '../components/WavePlayer.vue';
import wordDialog from '../components/WordDialog.vue';
// import RowSpan from '../components/RowSpan.vue'

export default {
  components: {
    WavePlayer, wordDialog,
  },
  data: function () {
    return {
      tag: "",
      id: 0,
      info0: {},
      info: {},
      source: {},
      tableData: [],
      items: ['漏读', '差', '良', '优'],
      words: [],
      answers: [],
      options: [],
      submitted: 1,
      choice: ["A", "B", "C", "D", "E", "F", "G"],
      content: [],
      centerDialogVisible: false,
      dialogType: "流利度",
      wordInfo: { phonetic: [], explanation: "" },
      loading1: false,
      ids: null
    }
  },
  created() {
    this.$bus.$on("word", (word) => {
      word = word.replace(/(^\W*)|(\W*$)/g, "");
      word = word.trim().toLowerCase();
      if (word) {
        this.$refs.wordDialog.show(word, this.source.tag);
      }
    })
    this.query()
  },

  mounted() {
    window.showWord = this.showWord
  },


  methods: {
    next(val) {
      let index = this.ids.indexOf(this.id)
      index += val;
      this.$router.replace({
        path: '/record',
        query: { id: this.ids[index], immersive: 1 }
      })
      this.query()
    },
    play(type, word) {
      this.src = this.$host + "/api/word/audio/" + word + "/" + type
      this.$refs.audio.src = this.src;
      this.$refs.audio.play();
    },
    showDetail(word) {
      let info = this.$store.getters.getWord(word);
      if (info) {
        this.wordInfo.explanation = info.explanation;
        this.wordInfo.phonetic.phonetic;
      } else {
        this.loading1 = true;
        this.$http.post("/api/word/info", { word: word }).then((info) => {
          this.wordInfo = info;
          this.$store.commit("addWord", info);
          this.loading1 = false;
        });
      }
    },

    showDialog(title) {
      this.dialogType = title;
      this.centerDialogVisible = true;
    },

    goBack() {
      if (this.$route.query.immersive) {
        this.$router.go(-1)
      } else {
        this.$router.replace("/");
      }
    },

    imageUrl() {
      return this.$host + '/upload/图片/DI/' + this.source.no + '.png';
    },
    query() {
      this.id = Number(this.$route.query.id)
      this.$http.post("/api/record/info", { id: this.$route.query.id, immersive: this.$route.query.immersive }).then((info) => {
        this.info = info.result;
        this.source = info.source;
        this.info0 = info;
        if (info.ids) this.ids = info.ids;
        if (this.info0.type == 1) {
          let content = {};
          if (this.info0.content) {
            JSON.parse(this.info0.content).forEach((data) => {
              content[data.index] = { url: data.url, text: data.text };
            })
          }
          this.info0.content = content;
          let audit_content = {};
          if (this.info0.audit_content) {
            JSON.parse(this.info0.audit_content).forEach((data) => {
              audit_content[data.index] = { url: data.url, text: data.text };
            })
          }

          this.info0.audit_content = audit_content;
        } else {
          this.content = this.info0.content.split(",");
        }


        if (['SMW', 'MCS', 'HCS', 'MCM-L', 'MCS-L', 'MCS-R', 'MCM-R', 'SST'].indexOf(this.source.tag) != -1) {
          this.answers = this.source.answer.split(',');
        }

        if (this.source.options) {
          let rows = this.source.options.replace(/\n+/g, '\n').split('\n');
          rows.forEach((row) => {
            this.options.push(row.replace(/\s+/g, ' ').split(" "));
          })
        }

        let words = [];
        let answers = [];
        if (this.source.tag == "HIW") {
          let items = this.source.question.replace(/\s+/g, ' ').split(' ');
          let results = this.info0.content.split(",");
          let index = 0;
          items.forEach((item) => {
            if (item.indexOf("/") != -1) {
              let parts = item.split("/");
              if (results[index] == parts[0]) {
                words.push({ word: results[index], class: "right choose" })
                words.push({ word: `(${parts[1]})`, class: "right" })

              } else {
                words.push({ word: `${parts[0]}`, class: "wrong1" })
                words.push({ word: `(${parts[1]})`, class: "wrong1" })
              }

              index++;
            } else {
              words.push({ word: item, class: "" })
            }
          })
          this.words = words;
          console.log(this.words);
        } else if (this.source.tag == "FIB-L" || this.source.tag == "FIB-R") {
          let items = this.source.question.replace(/\s+/g, ' ').split(' ');
          let results = this.info0.content.split(",");
          let answers = this.source.answer.split(",");
          let index = 0;
          items.forEach((item) => {
            if (item == "__" || item.indexOf("(") == 0) {
              if (results[index] == answers[index]) {
                words.push({ word: results[index], class: "right" })
              } else {
                if (results[index]) {
                  words.push({ word: results[index], class: "wrong" })
                } else {
                  words.push({ word: "______", class: 'ls0 wrong1' })
                }

                words.push({ word: `(${answers[index]})`, class: "right" })
              }
              index++;
            } else {
              words.push({ word: item, class: "" })
            }
          })
          this.words = words;
          console.log(this.words);
        } else if (this.source.tag == "FIB-RW") {
          let items = this.source.question.replace(/\s+/g, ' ').replace(/\s+\//g, '/').split(' ');
          let results = this.info0.content.split(",");
          let answers = this.source.answer.split(",");
          let index = 0;
          items.forEach((item) => {
            if (item.indexOf("(") == 0) {
              let parts = item.replace(/[()]/g, "").split("/");
              if (results[index] == answers[index]) {
                parts.forEach((part, index1) => {
                  if (index1 == 0) {
                    words.push({ word: "(", class: "divider" })
                  }
                  if (part == results[index]) {
                    words.push({ word: part, class: "right1 choose" })
                  } else {
                    words.push({ word: part, class: "" })
                  }
                  if (index1 == parts.length - 1) {
                    words.push({ word: ")", class: "divider" })
                  } else {
                    words.push({ word: "/", class: "divider" })
                  }
                })
              } else {
                parts.forEach((part, index1) => {
                  if (index1 == 0) {
                    words.push({ word: "(", class: "divider" })
                  }
                  if (part == results[index]) {
                    words.push({ word: part, class: "wrong choose" })
                  } else if (part.indexOf(answers[index]) != -1) {
                    words.push({ word: part, class: "right1" })
                  } else {
                    words.push({ word: part, class: "" })
                  }

                  if (index1 == parts.length - 1) {
                    words.push({ word: ")", class: "divider" })
                  } else {
                    words.push({ word: "/", class: "divider" })
                  }
                })
              }
              index++;
            } else {
              words.push({ word: item, class: "" })
            }
          })
          this.words = words;
          console.log(this.words);
        }

        if (this.source.tag == "WFD") {
          let items = this.source.answer.replace(/\s+/g, ',').split(',');
          let results = this.info0.content.split(",");
          results.forEach((item) => {
            if (items.indexOf(item)) {
              answers.push({ word: item, class: 'right' })
            } else {
              answers.push({ word: item, class: 'wrong' })
            }
          })
          this.answers = answers;
        } else if (this.source.tag == "FIB-RW") {
          answers = [];
          let items = this.source.answer.replace(/\s+/g, ',').split(',');
          let results = this.info0.content.split(",");
          results.forEach((item, index) => {
            if (item == items[index]) {
              answers.push({ word: item, class: 'right' })
            } else {
              if (item) {
                answers.push({ word: item, class: 'wrong' })
                answers.push({ word: "(" + items[index] + ")", class: 'wrong1' });
              } else {
                answers.push({ word: item + "(" + items[index] + ")", class: 'wrong1' })
              }

            }
            if (index < items.length - 1) {
              answers.push({ word: ",", class: 'divider' });
            }
          })
          this.answers = answers;
        } else if (this.source.tag == "FIB-R") {
          answers = [];
          let items = this.source.answer.replace(/\s+/g, ',').split(',');
          let results = this.info0.content.split(",");
          results.forEach((item, index) => {
            if (item == items[index]) {
              answers.push({ word: item, class: 'right' })
            } else {
              if (item) {
                answers.push({ word: item, class: 'wrong' })
                answers.push({ word: "(" + items[index] + ")", class: 'wrong1' });
              } else {
                answers.push({ word: item + "(" + items[index] + ")", class: 'wrong1' })
              }

            }
            if (index < items.length - 1) {
              answers.push({ word: ",", class: 'divider' });
            }
          })
          this.answers = answers;
        }

        if (this.source.tag == 'WFD') {
          let data = this.getWFDData1(this.source.answer, this.info0.content);
          this.answers = data;
        }

        if (this.source.tag == "SWT") {
          this.tableData.push({
            item1: "Content",
            item2: this.info.content + "/2",
            item4: parseInt(this.info.content / 2 * 100)
          })
          this.tableData.push({
            item1: "Form",
            item2: this.info.form + "/1",
            item4: parseInt(this.info.form / 1 * 100)
          })
          this.tableData.push({
            item1: "Grammar",
            item2: this.info.grammar + "/2",
            item4: parseInt(this.info.grammar / 2 * 100)
          })

          this.tableData.push({
            item1: "Vocabulary",
            item2: this.info.vocabulary + "/2",
            item4: parseInt(this.info.vocabulary / 2 * 100)
          })
        } else if (this.source.tag == "WE") {
          this.tableData.push({
            item1: "Content",
            item2: this.info.content + "/3",
            item4: parseInt(this.info.content / 3 * 100)
          })
          this.tableData.push({
            item1: "Form",
            item2: this.info.form + "/2",
            item4: parseInt(this.info.form / 2 * 100)
          })
          this.tableData.push({
            item1: "Grammar",
            item2: this.info.grammar + "/2",
            item4: parseInt(this.info.grammar / 2 * 100)
          })




          this.tableData.push({
            item1: "Vocabulary range",
            item2: this.info.vocabularyRange + "/2",
            item4: parseInt(this.info.vocabularyRange / 2 * 100)
          })

          this.tableData.push({
            item1: "General linguistic range",
            item2: this.info.linguisticRange + "/2",
            item4: parseInt(this.info.linguisticRange / 2 * 100)
          })


          this.tableData.push({
            item1: "Development, structure and coherence",
            item2: this.info.structure + "/2",
            item4: parseInt(this.info.structure / 2 * 100)
          })

          this.tableData.push({
            item1: "Spelling",
            item2: this.info.spelling + "/2",
            item4: parseInt(this.info.spelling / 2 * 100)
          })
        } else if (this.source.tag == "SST") {
          this.tableData.push({
            item1: "Content",
            item2: this.info.content + "/2",
            item4: parseInt(this.info.content / 2 * 100)
          })
          this.tableData.push({
            item1: "Form",
            item2: this.info.form + "/2",
            item4: parseInt(this.info.form / 2 * 100)
          })
          this.tableData.push({
            item1: "Grammar",
            item2: this.info.grammar + "/2",
            item4: parseInt(this.info.grammar / 2 * 100)
          })


          this.tableData.push({
            item1: "Vocabulary",
            item2: this.info.vocabulary + "/2",
            item4: parseInt(this.info.vocabulary / 2 * 100)
          })

          this.tableData.push({
            item1: "Spelling",
            item2: this.info.spelling + "/2",
            item4: parseInt(this.info.spelling / 2 * 100)
          })
        } else if (this.source.tag == "ASQ") {
          this.tableData.push({
            item1: "内容分",
            item2: info.score + "/1",
            item3: "流利度不仅仅是不卡顿，不重复和不犹豫，还包含了意群断句，节奏感，语速适中等其他要求。",
          })
        } else {
          this.tableData.push({
            item1: "内容分",
            item2: (parseInt(info.result.integrity * 0.9) < 10 ? 10 : parseInt(info.result.integrity * 0.9)) + "/90",
            item3: "流利度不仅仅是不卡顿，不重复和不犹豫，还包含了意群断句，节奏感，语速适中等其他要求。",
            item4: 0
          })

          this.tableData.push({
            item1: "发音",
            item2: (parseInt(info.result.pronunciation * 0.9) < 10 ? 10 : parseInt(info.result.pronunciation * 0.9)) + "/90",
            item3: "发音的评分标准比较综合，一共包含以下几点: 第一，音准问题，做到元音饱满辅音清晰；第二，有重弱读和单词重音；第三，要注意连读和省读现象，第四，不能蹦单词同时不能拖音。",
            item4: 0
          })

          this.tableData.push({
            item1: "流利度",
            item2: (parseInt(info.result.fluency * 0.9) < 10 ? 10 : parseInt(info.result.fluency * 0.9)) + "/90",
            item3: "漏读或者添加单词都是一个错误。考试遇到大词难词，可以直接跳过。不建议使用something替代难词，因为这样扣分比直接跳过要高。",
            item4: 0
          })

          setTimeout(() => {
            this.tableData[0].item4 = parseInt((parseInt(info.result.integrity * 0.9) < 10 ? 10 : parseInt(info.result.integrity * 0.9)) / 90 * 100);
            this.tableData[1].item4 = parseInt((parseInt(info.result.pronunciation * 0.9) < 10 ? 10 : parseInt(info.result.pronunciation * 0.9)) / 90 * 100)
            this.tableData[2].item4 = parseInt((parseInt(info.result.fluency * 0.9) < 10 ? 10 : parseInt(info.result.fluency * 0.9)) / 90 * 100)
          }, 800);
        }
        // if (info && info.result) {
        //   if (this.source.tag == "ASQ") {
        //     this.tableData.push({
        //       item1: "内容分",
        //       item2: info.score + "/1",
        //       item3: "流利度不仅仅是不卡顿，不重复和不犹豫，还包含了意群断句，节奏感，语速适中等其他要求。",
        //     })
        //   } else {

        //     this.tableData.push({
        //       item1: "流利度",
        //       item2: parseInt(info.result.fluency * 0.9) < 10 ? 10 : parseInt(info.result.fluency * 0.9) + "/90",
        //       item3: "漏读或者添加单词都是一个错误。考试遇到大词难词，可以直接跳过。不建议使用something替代难词，因为这样扣分比直接跳过要高。",
        //       item4: parseInt((parseInt(info.result.fluency * 0.9) < 10 ? 10 : parseInt(info.result.fluency * 0.9)) / 90 * 100)
        //     })
        //     this.tableData.push({
        //       item1: "发音",
        //       item2: parseInt(info.result.pronunciation * 0.9) < 10 ? 10 : parseInt(info.result.pronunciation * 0.9) + "/90",
        //       item3: "发音的评分标准比较综合，一共包含以下几点: 第一，音准问题，做到元音饱满辅音清晰；第二，有重弱读和单词重音；第三，要注意连读和省读现象，第四，不能蹦单词同时不能拖音。",
        //       item4: parseInt((parseInt(info.result.pronunciation * 0.9) < 10 ? 10 : parseInt(info.result.pronunciation * 0.9)) / 90 * 100)
        //     })
        //     this.tableData.push({
        //       item1: "内容分",
        //       item2: parseInt(info.result.integrity * 0.9) < 10 ? 10 : parseInt(info.result.integrity * 0.9) + "/90",
        //       item3: "流利度不仅仅是不卡顿，不重复和不犹豫，还包含了意群断句，节奏感，语速适中等其他要求。",
        //       item4: parseInt((parseInt(info.result.integrity * 0.9) < 10 ? 10 : parseInt(info.result.integrity * 0.9)) / 90 * 100)
        //     })

        //   }
        // }
      })
    },


    getWFDData() {
      let data = [];

      let answers0 = this.source.answer.replaceAll(".", "").split(" ");
      let contents0 = this.info0.content.replaceAll(".", "").split(" ");


      let answers = this.source.answer.toLowerCase().replaceAll(/[^\w\s]/g, "").split(" ");
      let contents = this.info0.content.toLowerCase().replaceAll(/[^\w\s]/g, "").split(" ");

      contents.forEach((content, index0) => {
        let index1 = answers.findIndex((val) => {
          return val.split("/").indexOf(content) != -1;
        })
        if (index1 != -1) {
          answers.splice(index1, 1);
          answers0.splice(index1, 1);

          if (index1 > 0) {
            let data2 = [];
            for (let index2 = 0; index2 < index1; index2++) {

              let index3 = contents.findIndex((val) => {
                return answers[index2].split("/").indexOf(val) != -1;
              })

              if (index3 == -1) {
                let word = answers0[index2];
                if (index2 == 0) {
                  word = "(" + word;
                }
                if (index2 == index1 - 1) {
                  word = word + ")";
                }
                data2.push(answers[index2])
                data.push({ word: word, class: 'wrong1' })
              }
            }
            // data = data2.concat(data);


            data2.forEach((item3) => {
              answers0.splice(answers.indexOf(item3), 1);
              answers.splice(answers.indexOf(item3), 1);
            })
          }
          if (data.length > 0 && data[data.length - 1].class == 'wrong1' && (data[data.length - 1].word.indexOf(')') == -1)) {
            data[data.length - 1].word = data[data.length - 1].word + ")";
          }
          data.push({ word: contents0[index0], class: 'right' });
        } else {
          data.push({ word: contents0[index0], class: 'wrong2' });
        }
      })
      answers.forEach((item4, index) => {
        let word = answers0[index];
        if (index == 0) {
          word = "(" + word;
        }
        if (index == answers.length - 1) {
          word = word + ")";
        }
        data.push({ word: word, class: 'wrong1' });
      })
      data[data.length - 1].word = data[data.length - 1].word + ".";
      return data;
    },

    getWFDData1(answer, content) {
      let data = [];
      let answers0 = answer.replace(/[,.]/g, "").split(" ");
      let contents0 = content.replace(/[,.]/g, "").split(" ");
      let answers = [];

      answers0.forEach((item, index) => {
        if (item.indexOf("^") == 0) {
          answers0[index] = item.replace("^", "");
          answers.push(item.replace("^", ""));
        } else {
          answers.push(item.toLowerCase());
        }
      })

      let contents = content.toLowerCase().replace(/[,.]/g, "").split(" ");

      answers.forEach((answer, index1) => {
        let index2 = contents.findIndex((val) => {
          return answer.split("/").indexOf(val) != -1;
        })

        if (index2 == -1) {
          index2 = contents0.findIndex((val) => {
            return answer.split("/").indexOf(val) != -1;
          })
        }

        if (index2 == -1) {
          data.push({ word: answers0[index1], class: 'wrong1' });
        } else {
          let count = 0;
          for (let index3 = 0; index3 <= index2; index3++) {
            let index4 = answers.findIndex((val, i) => {
              return i >= index1 && val.split("/").indexOf(contents[index3]) != -1;
            })

            if (index4 == -1) {
              index4 = answers.findIndex((val, i) => {
                return i >= index1 && val.split("/").indexOf(contents0[index3]) != -1;
              })
            }

            if (index4 == -1) {
              data.push({ word: contents0[index3], class: 'wrong2' });
            } else {
              data.push({ word: contents0[index3], class: 'right' });
            }
            count++;
          }
          contents0.splice(0, count);
          contents.splice(0, count);
        }
      });

      contents0.forEach((item) => {
        data.push({ word: item, class: 'wrong2' });
      })

      data.forEach((item, index) => {
        if (item.class == "wrong1" && (index == 0 || data[index - 1].class != "wrong1")) {
          item.word = "(" + item.word;
        }
        if (item.class == "wrong1" && ((index == data.length - 1) || data[index + 1].class != "wrong1")) {
          item.word = item.word + ")";
        }
      })
      data[data.length - 1]["word"] += ".";
      return data;
    },

    getLabel() {
      let text = "原文";
      if (['RS', 'RA', 'RL', 'ASQ', 'WFD', 'SMW', 'HCS', 'MCS', 'MCM-L', 'SST'].indexOf(this.source.tag) != -1) {
        text = "音频文本";
      } else if (this.source.tag == "WE") {
        text = "作文题目";
      }
      return text;
    },
    getAnswerLabel() {
      let text = "答案";
      if (this.source.tag == "WE") {
        text = "范文";
      } else if (this.source.tag == "SWT" || this.source.tag == "SST") {
        text = "参考答案";
      }
      return text;
    },


    getClass(item) {
      if (item.pronunciation == 0) {
        if (['RA', 'RS'].indexOf(this.source.tag) == -1) {
          return "v1";
        } else {
          return "v0";
        }
      } else if (item.pronunciation < 60) {
        return "v1";
      } else if (item.pronunciation < 85) {
        return "v2";
      } else if (item.pronunciation <= 100) {
        return "v3";
      }
    },

    showWord(word) {
      this.$bus.$emit('word', word);
    }
  },
};
</script>

<style scoped>
.media-box {
  width: 840px;
  margin: 40px auto;
  position: relative;
  overflow-x: hidden;
}

.media-box>>>.el-card {
  border: none !important;
}

/* 
.media-box>>>.el-card__body {
  padding: 0;
} */

.v0 {
  color: red;
}

.v1 {
  color: yellow;
}

.v2 {
  color: green;
}

.word {
  font-size: 18px;
}


.word.v0 {
  color: #909399;
}

.word.v1 {
  color: #F56C6C;
}

.word.v2 {
  color: #E6A23C;
}

.word.v3 {
  color: #67C23A;
}

.right {
  color: green;
  font-weight: bold;
}

.right1 {
  font-size: 18px;
  color: green;
  font-weight: bolder;
}

.dot {
  margin-top: 5px;
  height: 14px;
  width: 14px;
  border-radius: 50%;
}

.dot.v0 {
  background-color: #909399;
}

.dot.v1 {
  background-color: #F56C6C;
}

.dot.v2 {
  background-color: #E6A23C;
}

.dot.v3 {
  background-color: #67C23A;
}

.record>>>.el-progress.is-success .el-progress-bar__inner {
  background-color: #e6a13c;
}


.record>>>.word.right {
  color: green;
  font-weight: 500;
}

.record>>>.word.wrong,
.wrong {
  color: red;
  text-decoration: line-through;
  font-weight: 500;
}

.record>>>.word.wrong2,
.wrong2 {
  font-weight: 500;
  text-decoration: line-through;
}

.record>>>.word.wrong1,
.wrong1 {
  color: red;
  font-weight: 500;
}


.word-dot {
  line-height: 30px;
  margin-left: -2px;
  font-size: 16px;
}
</style>
